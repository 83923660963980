.square-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    width: 100%;
    border: 3px solid black;
}

.square {
    position: relative;
    border: 2px solid black;
    padding-top: 100%;
}

.square .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.animation-jump {
    animation: jumpAnimation 0.25s;
}

@keyframes jumpAnimation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}