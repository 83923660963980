.game-mode-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
}

.game-mode-selector__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    height: 70%;
    width: 75%;
    font-size: 25px;
    border: none;
    border-radius: 15px;
    color: white;
    max-width: 400px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0,.7);
    transition: 0.2s;
    border: 5px solid white;
}

.game-mode-selector__btn:hover{
    opacity: 85%;
}

.game-mode-selector__btn:hover:after {
    opacity: 1;
}

.game-mode-menu__description {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 65%;
    inline-size: 10%;
    color: beige;
    width: 90%;
    text-align: center;
    overflow-wrap: break-word;
    font-size: 12px;
    white-space: pre-wrap;
}

@media only screen and (min-width: 768px) {
    .game-mode-menu__description {
        top: 70%;
        font-size: 16px;
    }
}

#game-mode-menu__prompt {
    width: 75%;
    max-width: 300px;
    text-align: center;
    padding: 10px;
    border: 1px gray;
    border-style: solid none solid;
}

/*@media only screen and (min-width: 768px) {*/
/*    .game-mode-menu__btn {*/
/*    }*/
/*}*/

.game-mode-selector__btn--classic {
    background-color: mediumpurple;
}

.game-mode-selector__btn--puzzle {
    background-color: #F95700
}