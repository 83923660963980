.key {
    position: relative;
    border: 0;
    background-color: lightgrey;
    text-align: center;
    padding: 0;
    margin: 0 0.5%;
    font-size: 18px;
    font-weight: bold;
    color: black;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none;
}
.key:enabled:active {
    transform: translateY(2px);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.4);
}