.high-score::-webkit-scrollbar {
    height: 5px;
}

.high-score::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 3px;
}

.high-score::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.high-score::-webkit-scrollbar-track {
    background-color: white;
}